.ovaev-booking-btn {
	a {
		display: inline-block;
		font-size: 12px;
	    font-weight: 700;
	    line-height: 1;
	    text-transform: uppercase;
	    border-radius: 100px;
	    background-color: var(--primary);
	    color: #fff;
	    padding: 22px 40px;
	    -webkit-transition: all .3s ease;
	    -moz-transition: all .3s ease;
	    -o-transition: all .3s ease;
	    transition: all .3s ease;

	    &:hover {
	    	background-color: var(--heading)!important; 
	    }
	}
}