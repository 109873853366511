.ovaev-event-info {
	padding: 45px;
	background-color: #f5f5f5;
	border-top: 2px solid var(--primary);
	.info-contact {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		.label {
			display: block;
			font-weight: 700;
			list-style-type: 1.2;
			color: var(--heading);
		}
		.info, a {
			font-weight: 700;
			list-style-type: 1.2;
			color: var(--primary)
		}
		a {
			position: relative;
			&:before {
				transition: all .5s ease;
				position: absolute;
				content: "";
				left: 0;
				bottom: -3px;
				width: 0;
				height: 1px;
				background-color: var(--primary); 
			}
			&:hover {
				&:before {
					transition: all .5s ease;
					width: 100%;
				}
			}
		}
	}
}