//css archive event
.archive_event {
  margin-bottom: 110px;
	&.col1 {
		display: grid;
  		grid-template-columns: 1fr;
  		grid-gap: 20px;
	} //column 1

	&.col2 {
		display: grid;
  		grid-template-columns: 1fr 1fr;
  		grid-gap: 20px;
  		@media (max-width: 768px) {
  			grid-template-columns: 1fr;
  		}
	} //column 2

	&.col3 {
		display: grid;
  		grid-template-columns: 1fr 1fr 1fr;
  		grid-gap: 20px;
  		@media (max-width: 1024px) {
  			grid-template-columns: 1fr 1fr;
  		}
  		@media (max-width: 768px) {
  			grid-template-columns: 1fr;
  		}
	} //column 3
}