// css .type2
.type2 {
	height: 100%;
	position: relative;
	box-shadow: 0px 10px 40px 0px rgba(6, 22, 58, .1);
	
	.date-event {
	    position: absolute;
	    margin-left: 20px;
	    top: 20px;
	    background-color: var(--primary);
	    text-align: center;
	    display: block;
	    padding: 8px 12px 4px 12px;
	    color: #fff;
	    z-index: 99;
		span {
			padding: 0px 0px 0px 0px;
		    font-size: 24px;
		    line-height: 22px;
		    font-weight: bold;
		    display: block;
		    margin-bottom: 3px;
		    text-transform: uppercase;
		}
		.month {
			font-size: 16px;
		}
	}
	.desc {
		.event-thumbnail {
			background-size: cover;
		    background-repeat: no-repeat;
		    background-position: center;
		    width: 100%;
		    min-height: 445px;
			img {
				display: none;
				width: 100%;
				transition: .3s all ease;
				-o-transition: .3s all ease;
				-webkit-transition: .3s all ease;
			}
			&:before {
			    background: linear-gradient(0deg , rgba(16, 19, 31, 0.9) 0%, rgba(16, 19, 31, 0.1) 100%);
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    content: "";
			}
		}
		.event_post {
			position: absolute;
		    left: 0;
		    bottom: 0;
		    text-align: left;
		    padding: 0px 30px 30px 20px;
		    color: #fff !important;
			@media(min-width: 1024px) and (max-width: 1099px){
				padding-left: 20px;
				padding-right: 20px;
			}
			.event_title {
				font-size: 22px;
				line-height: 26px;
				letter-spacing: 0.2px;
				color: #fff;
				text-transform: capitalize;
				margin-top: 0;
				margin-bottom: 15px;
				padding: 0;
				a {
					font-size: 22px;
					line-height: 26px;
					letter-spacing: 0.2px;
					color: #fff;
					-webkit-transition: all .3s ease;
					-o-transition: all .3s ease;
					transition: all .3s ease;
					font-weight: 400;
					text-decoration: none;
					&:hover {
						color:var(--primary);

					}
				}
			}
			.time-event {
				line-height: 22px;
				color: #fff;
				font-size: 16px;
				font-weight: 400;
				.time {
					margin-bottom: 7px;
					.more_date_text {
						&:hover {
							color: #fff;
						}
					}
				}
				.time, .venue {
					display: flex;
					align-items: center;
					i {
						color: var(--primary);
						font-size: 16px;
					}
				}
				svg {
					color: var(--primary);
					width: 18px;
					margin-right: 6px;
				}
			}
		}
	}
}