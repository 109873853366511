//css .type1
.type1 {
	height: 100%;
	position: relative;
	background-color: #f5f5f5;
	&:hover {
		.date-event{
			transition: .5s all ease;
			-o-transition: .5s all ease;
			-webkit-transition: .5s all ease;
			left: 0;
			opacity: 1;
			visibility: visible;
		}
	}

	.date-event {
		transition: .5s all ease;
		-o-transition: .5s all ease;
		-webkit-transition: .5s all ease;
		position: absolute;
		top: 0;
		left: -70px;
		opacity: 0;
		visibility: hidden;
		display: flex;
		justify-content: center;
		height: 100%;
		z-index: 2;
		background-color: rgba(255, 79, 0, 0.9);
		color: #fff;
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		line-height: 1.3;
		letter-spacing: 2px;
		writing-mode: tb-rl;
        transform: rotate(-180deg);
        .date {
            padding: 0 25px;
            display: inline-block;
        }
	}

	.desc {
		.event-thumbnail {
			overflow: hidden;
			position: relative;
			a {
				img {
					width: 100%;
					display: block;
					min-height: 235px;
					object-fit: cover;
					transition: .3s all ease;
					-o-transition: .3s all ease;
					-webkit-transition: .3s all ease;
				}
			}
		}
		.event_post {
			padding: 40px 30px;
			text-align: center;
			border-top: 0;
			-webkit-transition: all 3s;
			transition: all .3s;
			@media(min-width: 1024px) and (max-width: 1099px){
				padding-left: 20px;
				padding-right: 20px;
			}

			.meta-event {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 10px;
				.author {
					margin-right: 14px;
					a {
						color: var(--text);
						&:hover {
							color: var(--primary);
						}
					}
				}
			}

			.event_title {
				font-size: 26px;
				font-weight: 700px;
				line-height: 1.2;
				letter-spacing: 0.2px;
				color: var(--heading);
				margin: 0;
				padding-bottom: 5px;
				a {
					color: var(--heading);
					&:hover {
						color: var(--primary);
					}
				}
			}

			.venue-event {
				line-height: 1.2;
				color: var(--text);
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 1.5px;
				text-transform: uppercase;
				margin-bottom: 20px;
				.venue {
					i {
						display: none;
					}
				}
			}

			.button_event {
				margin-top: 10px;
				display: inline-flex;
				align-items: center;
				.view_detail {
					background: #fff;
					margin-right: 12px;
					border-radius: 100px;
					font-weight: 700;
					text-transform: uppercase;
					-webkit-transition: all .3s;
					transition: all .3s;
					color: var(--heading);
					font-size: 12px;
					line-height: 1.2;
					letter-spacing: 0.2px;
					padding: 18px 30px;
					&:hover {
						color: #fff;
						background-color: #ff3514;
                        border-color: #ff3514;
					}
				}
			}
		}
	}
}