.ovaev-event-navigation {
	margin: 0;

	.content-event {
		margin: 0;

		.ova-next-pre-post {
			margin: 0;
		}
	}
}